// Style Customizations for base page layout for MLM

.ms-webpart-zone.ms-fullWidth {
	display: flex;
	flex-direction: column;
}

div#contentRow {
	padding-top: 0;
}

div#contentBox {
	max-width: initial !important;
	margin: 0;
}

.ribbon-visible {
	.content-zone {
		// min-height: calc(100vh - 320px) !important;
	}
}

.zone-container {
	display: flex;
	flex: 1 1 0px;
	flex-direction: column;

	/* ==================== HEAD ZONE ================= */
	.head-zone {
		background: white;
		width: 100%;
		max-width: 100%;
		border-bottom-width: 0;
		z-index: 1;
		box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1) !important;

		> .ms-webpart-zone {
			> .ms-webpartzone-cell {
				margin-bottom: 0;
			}
		}

		&.noadd {
			.ms-SPButton.ms-WPAddButton {
				display: none !important;
			}
		}
	}

	/* ============== END HEAD ZONE SPECIFIC SETINGFS ============== */
	/* ============================================================= */

	/* ==================== CONTENT ZONE ================= */
	.content-zone {
		max-width: 1300px;
		align-self: center;
		width: 100%;
		padding: 0 1.3em 0 1.3em;
		box-sizing: border-box;
    margin-bottom: 3em;
    font-size: 1em;

		// web part defaults
		.ms-webpartzone-cell {
			margin: auto auto 1.3em;

			/*
      &:first-child {
        margin-top: 20px;
      }
      */

			// remove bottom margin for last web part on page
			&:last-child {
				margin-bottom: 0 !important;
			}
		}

		// only web part... and web part has a border
		.ms-webpart-chrome:only-child {
			.ms-WPBorderBorderOnly {
				box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
				border-color: transparent;
				//  margin-top: -21px;
			}
		}
	}

	/* ============ LEADS PAGE SPECIFIC =========== */
	&.leads {
		.head-zone {
			display: none;
		}

		.content-zone {
			min-height: 0 !important;
			max-width: 100% !important;
			padding: 0 !important;
		}
	}

	/* ============ STATES PAGES SPECIFIC ========= */

	&.states {
		.head-zone {
			box-shadow: none !important;
			border-bottom: 0;

			.state-title {
				text-align: center;
				pointer-events: none;
				background-color: silver;
				padding: 2.5rem 0.5rem;
				font-size: 2.5rem;

				a {
					color: #555;
				}
			}
		}

		.content-zone {
			margin-left: 0;
			margin-right: 0;
			height: auto;
			min-height: auto;
		}
	}
}

// ================ END ZONE CONTAINER ==================

/* ============== RESPONSIVE TABLE / MOBILE ==============*/

@media only screen and (max-width: 639px) {
	.zone-container {
		.content-zone {
			margin-left: 0.66em;
			margin-right: 0.66em;
			box-sizing: border-box;
		}
	}

	div#contentRow {
		outline: 0;
		/* Start out as collapsed */
		/* margin-left: -220px; */
	}
}
